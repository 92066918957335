/* eslint-disable react/prop-types */
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Home from './pages/Home/index';
import ListService from './pages/ListService/index';
import OrchIntroduction from './pages/OrchIntroduction/index';
import OrchAsync from './pages/OrchAsync';
import OrchSync from './pages/OrchSync';
import { ROUTE_PATH } from './helpers/constants';

const renderNotFound = () => (
  <div>
    <h1>Page not found</h1>
    <br />
    <br />
    <h3><a href={ROUTE_PATH.ROOT}>Ir para raiz</a></h3>
  </div>
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path={ROUTE_PATH.ROOT} exact component={Home} />
      <Route path={ROUTE_PATH.HOME} exact component={Home} />
      <Route path={ROUTE_PATH.ORCH_DOC} exact component={OrchIntroduction} />
      <Route path={ROUTE_PATH.ORCH_DOC_ASYNC} exact component={OrchAsync} />
      <Route path={ROUTE_PATH.ORCH_DOC_SYNC} exact component={OrchSync} />
      <Route path={ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC} exact component={ListService} />

      {/* retrocompatibilities routes */}
      <Route path="/introduction_orch" exact><Redirect to={ROUTE_PATH.ORCH_DOC} /></Route>
      <Route path="/list" exact component={({ location }) => (<Redirect to={{ pathname: ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC, search: location.search }} />)} />

      <Route path="*" component={renderNotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
