import { ROUTE_PATH } from '../../helpers/constants';

const ORCH_ENDPOINT_SERVICES = '/v1/services';

export default `

# Orquestrador - Serviços Assíncronos

Essa documentação descreve as chamadas para **serviços assíncronos**.

> Veja a lista de serviços [clicando aqui](${ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC})

##### Características:
- As respostas são no formato json.
- As chamadas são assíncronas, então precisa realizar a chamada (POST) e depois obter os dados por GET ou Callback (Callback é o recomendado).
- Para cada chamada é retornado um token de identificação do chamada.
- É necessário ter um \`user_id\` e \`x-api-key\` para realizar as chamadas.

## Tópicos

- [1 - Como utilizar](#1---como-utilizar)
  - [1.1 - Fazendo a chamada](#1-1---fazendo-a-chamada)
  - [1.2 - Obtendo a resposta da chamada via GET](#1-2---obtendo-a-resposta-da-chamada-via-get)
  - [1.3 - Obtendo a resposta da chamada via Callback](#1-3---obtendo-a-resposta-da-chamada-via-callback)
- [2 - Mensagens e Status](#2---mensagens-e-status)
  - [2.1 - Status de Erro](#2-1---status-de-erro)
  - [2.2 - Status de Informação](#2-2---status-de-informação)
  - [2.3 - Status de Sistema de origem com problemas](#2-3---status-de-sistema-de-origem-com-problemas)

## 1 - Como utilizar

### 1.1 - Fazendo a chamada

Para fazer a chamada, é necessário fazer uma requisição do tipo POST para a rota:
> ${ORCH_ENDPOINT_SERVICES}

e no corpo da requisição, o json com o seguinte formato:

\`\`\`json
{
  "user_id": "...",
  "services": [
    {
      "service_id": "...",
      "data": {...}
    }
  ],
  "callbacks": [
    {
      "type": "...",
      "endpoint": "..."
    }
  ]
}
\`\`\`

Descrição dos campos do \`POST\`:

| Campo                        | Tipo   | Obrigatório | Descrição                                                                               |
|------------------------------|--------|-------------|-----------------------------------------------------------------------------------------|
| user_id                      | String | *           | id do seu usuário                                                                       |
| services                     | Array  | *           | array de objetos com os serviços a serem chamados, ao menos 1 objeto deve ser fornecido |
| services.$.service_id        | String | *           | id do serviço                                                                           |
| services.$.data              | Objeto | *           | inputs que serão enviados para o serviço                                                |
| services.$.mapped_service_id | String |             | é utilizado quando é fornecido dois service_id's iguais e é necessário diferencia-los.  |
| callbacks                    | Array  |             | vejá a descrição na seção "Callbacks"                                                   |

Exemplo de requisição onde será chamado dois serviços de uma vez \`certificate_alcb_cpf\` e \`cadastral_situation_cpf\`

\`\`\`json
{
  "user_id": "{{user_id}}",
  "services": [
    {
      "service_id": "certificate_alcb_cpf",
      "data": {
        "cpf": "012345678901"
      }
    },
    {
      "service_id": "cadastral_situation_cpf",
      "data": {
        "cpf": "012345678901",
        "birth_date": "25-01-1990"
      }
    }
  ]
}
\`\`\`

Resposta da requisição:
\`\`\`json
{
  "services": [
    {
      "id": "cadastral_situation_cpf",
      "service_id": "cadastral_situation_cpf",
      "token": "d1a712dd86c0a0da1959c"
    },
    {
      "id": "certificate_tax_debts_sp_cpf",
      "service_id": "certificate_tax_debts_sp_cpf",
      "token": "8fc19b55893d07ea07c3d"
    }
  ]
}
\`\`\`

Descrição dos campos de resposta do \`POST\`:

| Campo                 | Tipo   | Descrição                                                                                                                        |
|-----------------------|--------|----------------------------------------------------------------------------------------------------------------------------------|
| services              | Array  | array de objetos com os serviços que foram chamados                                                                              |
| services.$.service_id | String | id do serviço chamado                                                                                                            |
| services.$.id         | String | o campo id tem o mesmo valor do service_id e **não deve ser usado**, ele é retornado apenas por questão de retrocompatibilidade. |
| services.$.token      | String | o campo token identifica a chamada, esse campo servirá para obter a resposta.                                                    |


### 1.2 - Obtendo a resposta da chamada via \`GET\`

Para obter o resultado da chamada é necessário fazer uma requisição do tipo \`GET\` para a rota:
> ${ORCH_ENDPOINT_SERVICES}?user_id={{user_id}}&token={{token}}

Como o processamento da requisição é **assíncrono**, o resultado tem 3 status \`Processando\`, \`Processado\` ou \`Error\`:

#### 1.2.1 - Processando

Se o pedido estiver processando, a requisição irá retornar status **202** e o corpo virá de duas formas:

> Para todos esses casos, **espere um tempo** e refaça a requisição para obter o resultado.

* Se estiver processando, o corpo da resposta será um json vázio:

\`\`\`json
{}
\`\`\`

* Se o sistema de origem estiver com problemas para responder, o corpo da resposta será um json com uma mensagem informando o porquê da demora (Essa mensagem informa que o sistema de origem está com problemas e continuaremos processando até obter a resposta):

\`\`\`json
{
  "data": {
    "msg_infos": [
      {
        "status": "ORIGIN_SYSTEM_INFO",
        "msg": "O sistema de origem está com problemas para responder e iremos retentar em breve."
      }
    ]
  }
}
\`\`\`

#### 1.2.2 - Processado

Se o pedido estiver processado, a requisição irá retornar status **200** e o corpo virá da seguinte forma:

\`\`\`json
{
  "service_id": "certificate_tax_debts_sp_cpf",
  "data": {
    "cpf": "12345678901",
    "consists": false,
    "document_url": "https://bucket.s3.sa-east-1.amazonaws.com/folder/file_name.pdf",
    "certificate_create_date": "01-02-2019",
    "certificate_create_time": "15:30",
    "msg_infos": [],
    "msg_errors": []
  }
}
\`\`\`

#### 1.2.3 - Erro

Se a solicitação do pedido for incorreta (ex: no POST for fornecido um \`service_id\` que não existe), a requisição irá retornar status **422** e o corpo virá da seguinte forma:

\`\`\`json
{
  "service_id": "ABCxyz123",
  "msg_errors": [
    {
      "status": "NO_DATA_FOUND_ERROR",
      "msg": "o serviço ABCxyz123 não existe"
    }
  ]
}
\`\`\`

### 1.3 - Obtendo a resposta da chamada via **Callback**

O callback é uma outra forma de obter a resposta, com ele não é necessário chamar a rota de \`GET\`, ao momento que cada serviço tem o status alterado é feito uma requisição \`POST\` para o seu webhook.

##### Caracteristicas:
- É feito uma requisição POST enviando um json para o webhook.
- Caso o seu webhook retorne status code diferente de \`2XX\` ou esteja fora do ar, o callback retentará enviar por 30 vezes com os intervalos de tempo: 10s, 30s, 5min, 1h e o restante a cada 10h.

Para utiliza-lo, basta informar o campo \`callbacks\` junto da requisição \`POST\`:

\`\`\`json
{
  "user_id": "...",
  "services": [...],
  "callbacks": [
    {
      "type": "url",
      "endpoint": "...",
      "headers": { ... }
    }
  ]
}
\`\`\`

Descrição dos campos de callback:

| Campo                | Tipo                                 | Obrigatório | Descrição                                                  |
|----------------------|--------------------------------------|-------------|------------------------------------------------------------|
| callbacks            | Array                                | *           | Array de objetos com os callbacks que devem ser chamados   |
| callbacks.$.type     | String                               | *           | O campo type é uma constante e deve receber o valor \`url\`|
| callbacks.$.endpoint | String                               | *           | Url para onde a resposta será enviada                      |
| callbacks.$.headers  | Objeto (Os valores devem ser String) |             | Usado para enviar informações no header para o seu webhook |

Ao momento que um dos serviços requisitados muda de status, o seu endpoint irá receber o seguinte json de resposta via \`POST\`:

\`\`\`json
{
  "user_id": "...",
  "service_id": "...",
  "id": "...",
  "token": "...",
  "status": "...",
  "mapped_service_id": "...",
  "data": "{ ... }"
}
\`\`\`

Descrição dos campos de resposta que seu endpoint irá receber:

| Campo             | Tipo   | Descrição                                                                                                                        |
|-------------------|--------|----------------------------------------------------------------------------------------------------------------------------------|
| user_id           | String | id do usuário                                                                                                                    |
| service_id        | String | id do serviço chamado                                                                                                            |
| id                | String | o campo id tem o mesmo valor do service_id e **não deve ser usado**, ele é retornado apenas por questão de retrocompatibilidade. |
| token             | String | id da requisição.                                                                                                                |
| status            | String | O status do pedido, ele é uma constante e tem 3 possíveis valores: PROCESSED, PROCESSING ou ERROR                                |
| mapped_service_id | String | Esse campo virá se você o forneceu ao chamar o serviço.                                                                          |
| data              | Objeto | dados da resposta, são diferentes para cada serviço                                                                              |

Exemplo de requisição onde será chamado dois serviços de uma vez \`certificate_alcb_cpf\` e \`cadastral_situation_cpf\` com callbacks.

\`\`\`json
{
  "user_id": "{{user_id}}",
  "services": [
    {
      "service_id": "certificate_alcb_cpf",
      "data": {
        "cpf": "012345678901"
      }
    },
    {
      "service_id": "cadastral_situation_cpf",
      "mapped_service_id": "token_que_identifica_este_pedido",
      "data": {
        "cpf": "012345678901",
        "birth_date": "25-01-1990"
      }
    }
  ],
  "callbacks": [
    {
      "type": "url",
      "endpoint": "https://host/api/v1/your/endpoint",
      "headers": {
        "Authorization": "Bearer abcxyz",
        "header2": "outro header"
      },
    }
  ]
}
\`\`\`

No exemplo acima, o endpoint \`https://host/api/v1/your/endpoint\`, será chamado 2 vezes (1 para cada serviço) recebendo via POST os jsons:

\`\`\`json
{
  "user_id": "{{user_id}}",
  "id": "certificate_alcb_cpf",
  "service_id": "certificate_alcb_cpf",
  "token": "abc123xyz",
  "status": "PROCESSED",
  "data": "{ ... }"
}
\`\`\`

\`\`\`json
{
  "user_id": "{{user_id}}",
  "id": "cadastral_situation_cpf",
  "service_id": "cadastral_situation_cpf",
  "token": "xyz789qwe",
  "mapped_service_id": "token_que_identifica_este_pedido",
  "status": "PROCESSED",
  "data": "{ ... }"
}
\`\`\`

e no Header:

\`\`\`json
"Authorization": "Bearer abcxyz",
"header2": "outro header"
\`\`\`

#### 1.3.1 - Comportamento quando o sistema de origem está com problemas

Algumas vezes o sistema de origem pode estar com problemas para responder, nesse caso será enviado para o callback o \`json\` abaixo, que informa que há algum problema no sistema de origem e continuaremos **processando** até obter a resposta:

\`\`\`json
{
  "user_id": "{{user_id}}",
  "id": "...",
  "service_id": "...",
  "token": "...",
  "mapped_service_id": "...",
  "status": "PROCESSING",
  "data": {
    "msg_infos": [
      {
        "status": "ORIGIN_SYSTEM_INFO",
        "msg": "O sistema de origem está com problemas para responder e iremos retentar em breve."
      }
    ]
  }
}
\`\`\`

Ao momento que o sistema de origem volta ao ar, nosso sistema obtem a resposta e envia para o callback a resposta processada.

## 2 - Mensagens e Status

A mensagens e status são padronizados e tem 3 tipos: **Erro**, **Informação** e **Sistema de origem com problema**

### 2.1 - Status de Erro

Caso seja feita uma requisição incorreta para o orquestrador, ele irá retornar uma mensagem de erro junto do status code.

Tabela de mensagens de erro:

| Constante Status         | Descrição                                                    | Status Code |
|--------------------------|--------------------------------------------------------------|-------------|
| EXTERNAL_SERVER_ERROR    | Não foi possível obter as informações do servidor de origem. | 422         |
| INVALID_PARAMETERS_ERROR | Parâmetros inválidos.                                        | 400         |
| MISSING_PARAMETERS_ERROR | Há parâmetros obrigatórios ausentes.                         | 400         |
| NO_DATA_FOUND_ERROR      | Nenhum dado encontrado. (ao fornecer um token inexistente)   | 404         |
| GATEWAY_ERROR            | Limite de consultas excedido.                                | 429         |
| GATEWAY_ERROR            | x-api-key inválida.                                          | 403         |
| PROCESS_DATA_ERROR       | Ocorreu um erro ao processar os dados.                       | 422         |

### 2.2 - Status de Informação

As mensagens de informação são obtidas diretamente do sistema de origem.

Tabela de mensagens de informação:

| Constante Status           | Descrição                                                                                                | Status Code |
|----------------------------|----------------------------------------------------------------------------------------------------------|-------------|
| INVALID_PARAMETERS_INFO    | Paramêtros são inválidos para o sistema de origem.                                                       | 200         |
| UNAUTHORIZED_RESPONSE_INFO | Resposta não autorizada pelo sistema de origem.                                                          | 200         |
| NO_DATA_FOUND_INFO         | Nenhum dado encontrado no sistema de origem.                                                             | 200         |
| PDF_NOT_READED_INFO        | Não foi possível ler o conteúdo do pdf devido a má codificação do arquivo gerado pelo sistema de origem. | 200         |
| ORIGIN_SYSTEM_INFO         | O sistema de origem está com problemas para responder e iremos retentar em breve.                        | 202         |
| ORIGIN_SYSTEM_MESSAGE_INFO | Mensagem vinda do sistema de origem.                                                                     | 200         |


### 2.3 - Status de Sistema de origem com problemas

A mensagen é enviada para informar que o sistema de origem está com problemas e continuaremos processando até obter a resposta.

Tabela de mensagens de processando:

| Constante Status   | Descrição                                                                         | Status Code |
|--------------------|---------------------------------------------------------------------------------- |-------------|
| ORIGIN_SYSTEM_INFO | O sistema de origem está com problemas para responder e iremos retentar em breve. | 202         |
`;
