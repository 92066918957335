const ROUTE_PATH = {
  ROOT: '/',
  HOME: '/home',
  ORCH_DOC: '/orchestrator',
  ORCH_DOC_ASYNC: '/orchestrator_async',
  ORCH_DOC_SYNC: '/orchestrator_sync',
  ORCH_LIST_SERVICES_ASYNC: '/list_services_async',
};

export {
  ROUTE_PATH,
};
