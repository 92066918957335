/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import _ from 'lodash'
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Pagination,
  Collapse,
  Spinner,
  Tabs,
  Tab,
  FormGroup,
  FormLabel,
  FormCheck,
} from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { listService, getDocApi, reqMsType } from '../../services/api';
import { ROUTE_PATH } from '../../helpers/constants';
import Menu from '../../components/Menu/Menu';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import './styles.css';

const TreatedInputsJson = ({ serviceId, inputsOfService, inputsSchemaOfService }) => {
  if (inputsOfService != null) {
    const optionalText = '(opcional)'
    let newInputsOfService = _.omit(inputsOfService, 'token');
    newInputsOfService = _.mapValues(newInputsOfService, (val, key) => (
      inputsSchemaOfService[key]['required'] === true ? val : `${val} ${optionalText}`
    ))

    const fieldData = JSON.stringify(newInputsOfService);
    try {
      const stringToPrepareJson = `{"user_id": "", "services": [{"service_id": "${serviceId}", "data": ${fieldData}}]}`;

      return (
        <SyntaxHighlighter language="json" style={monokai}>
          {JSON.stringify(JSON.parse(stringToPrepareJson), null, 2)}
        </SyntaxHighlighter>
      );
    } catch (err) {
      console.log(err);
      return <div />;
    }
  } else {
    return <div>Documentação não encontrada para esse serviço</div>;
  }
};

const TreatedSchemaInputsJson = ({ jsonOfService, serviceId }) => {
  if (jsonOfService != null) {
    const newJsonOfService = { ...jsonOfService, token: undefined };
    const fieldData = JSON.stringify(newJsonOfService);
    try {
      const stringToPrepareJson = `{"service_id": "${serviceId}", "data": ${fieldData} }`;

      return (
        <SyntaxHighlighter language="json" style={monokai}>
          {JSON.stringify(JSON.parse(stringToPrepareJson), null, 2)}
        </SyntaxHighlighter>
      );
    } catch (err) {
      console.log(err);
      return <div />;
    }
  } else {
    return <div>Documentação não encontrada para esse serviço</div>;
  }
};

const TreatedOutputsJson = ({ outputsOfService, serviceId }) => {
  if (outputsOfService != null) {
    const fieldData = JSON.stringify(outputsOfService);
    try {
      const stringToPrepareJson = `{"service_id": "${serviceId}", "data": ${fieldData} }`;

      return (
        <SyntaxHighlighter language="json" style={monokai}>
          {JSON.stringify(JSON.parse(stringToPrepareJson), null, 2)}
        </SyntaxHighlighter>
      );
    } catch (err) {
      console.log(err);
      return <div />;
    }
  } else {
    return <div>Documentação não encontrada para esse serviço</div>;
  }
};

const ServiceType = ({ serviceType }) => {
  if (serviceType != null) {
    return (
      <span style={{ fontSize: '10px' }}>
        <b>à entidade: </b>
        {' '}
        {serviceType.to_entities !== null ? serviceType.to_entities.join(', ') : ''}
        {' '}
        <b>tipo: </b>
        {' '}
        {serviceType.type}
        {' '}
        <b>font de dados: </b>
        {' '}
        {serviceType.source}
        {' '}
        <b>divisão: </b>
        {' '}
        {serviceType.division}
        {' '}
        <b>estado: </b>
        {' '}
        {serviceType.state}
        {' '}
        <b>cidade: </b>
        {' '}
        {serviceType.city}
      </span>
    );
  }
  return '';
};

export default class ListService extends Component {
  state = {
    data: [],
    msTypes: null,
    indexCollapseOpen: -1,
    schemaInputs: null,
    schemaOutputs: null,
    treatedInputs: null,
    treatedOutputs: null,
    isSearchingDoc: false,
    radioActived: 'all',
    filterText: '',
    serviceType: {
      type: '',
      division: '',
      source: '',
      state: '',
      city: '',
      to_entities: [],
    },
  };

  queryFilter = null;

  itensPerPage = 25;

  pageCurrent = 1;

  totalPages = 1;

  paginationItems = [];

  currentService = null;

  async componentDidMount() {
    const url = window.location.href;
    const queryFilters = url.split('?');
    if (queryFilters.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      this.queryFilter = `?${queryFilters[1]}`;

      this.setInitialValue(queryFilters[1]);
    }
    try {
      const response = await listService(this.queryFilter);

      this.itensPerPage = response.headers['x-per-page'];
      const itensTotal = response.headers['x-total'];
      const aditionalPage = itensTotal % this.itensPerPage > 0 ? 1 : 0;
      this.totalPages = Math.floor(itensTotal / this.itensPerPage) + aditionalPage;
      this.pagination();

      this.setState({
        data: response.data.services,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }

    try {
      const response = await reqMsType({});

      this.setState({
        msTypes: response.data.ms_type,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }
  }

  async onSearchServicesClick() {
    let queryUrl = '';
    this.state.serviceType.to_entities.forEach((element) => {
      if (element.length > 0) {
        queryUrl += `&filter_to_entities[]=${element}`;
      }
    });
    if (this.state.serviceType.type !== null && this.state.serviceType.type.length > 0) {
      queryUrl += `&filter_type=${this.state.serviceType.type}`;
    }
    if (this.state.serviceType.city !== null && this.state.serviceType.city.length > 0) {
      queryUrl += `&filter_city=${this.state.serviceType.city}`;
    }
    if (this.state.serviceType.division !== null && this.state.serviceType.division.length > 0) {
      queryUrl += `&filter_division=${this.state.serviceType.division}`;
    }
    if (this.state.serviceType.state !== null && this.state.serviceType.state.length > 0) {
      queryUrl += `&filter_state=${this.state.serviceType.state}`;
    }
    if (this.state.serviceType.source !== null && this.state.serviceType.source.length > 0) {
      queryUrl += `&filter_source=${this.state.serviceType.source}`;
    }
    if (this.state.filterText !== null && this.state.filterText.length > 0) {
      queryUrl += `&filter_text=${this.state.filterText}`;
    }
    if (
      this.state.radioActived !== null
      && this.state.radioActived !== 'all'
      && this.state.radioActived.length > 0
    ) {
      queryUrl += `&filter_actived=${this.state.radioActived}`;
    }
    if (queryUrl !== '') {
      queryUrl = `?${queryUrl.slice(1, queryUrl.length)}`;
    }
    this.queryFilter = queryUrl;
    window.history.pushState('', '', `${ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC}${this.queryFilter}`);

    this.pageCurrent = 1;
    try {
      this.paginationItems = [];
      const response = await listService(this.queryFilter);

      this.itensPerPage = response.headers['x-per-page'];
      const itensTotal = response.headers['x-total'];
      const aditionalPage = itensTotal % this.itensPerPage > 0 ? 1 : 0;

      this.totalPages = Math.floor(itensTotal / this.itensPerPage) + aditionalPage;

      this.pagination();
      this.setState({
        data: response.data.services,
        indexCollapseOpen: -1,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }
  }

  onServiceClick(serviceId) {
    if (this.currentService !== serviceId) {
      this.setState({
        schemaInputs: null,
        schemaOutputs: null,
        treatedInputs: null,
        treatedOutputs: null,
        isSearchingDoc: true,
      });
      this.currentService = serviceId;
      this.searchApiDoc(serviceId);
    }
  }

  setInitialValue(query) {
    let filterText = '';
    let filterActived = 'all';
    let serviceTypeType = '';
    let serviceTypeDivision = '';
    let serviceTypeSource = '';
    let serviceTypeState = '';
    let serviceTypeCity = '';
    const serviceTypeToEntities = [];
    let pageCurrent = 1;

    if (query.includes('filter_text=')) {
      const aux = query.split('filter_text=')[1];
      const index = aux.indexOf('&');
      filterText = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_actived=')) {
      const aux = query.split('filter_actived=')[1];
      const index = aux.indexOf('&');
      filterActived = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_type=')) {
      const aux = query.split('filter_type=')[1];
      const index = aux.indexOf('&');
      serviceTypeType = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_division=')) {
      const aux = query.split('filter_division=')[1];
      const index = aux.indexOf('&');
      serviceTypeDivision = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_source=')) {
      const aux = query.split('filter_source=')[1];
      const index = aux.indexOf('&');
      serviceTypeSource = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_state=')) {
      const aux = query.split('filter_state=')[1];
      const index = aux.indexOf('&');
      serviceTypeState = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_city=')) {
      const aux = query.split('filter_city=')[1];
      const index = aux.indexOf('&');
      serviceTypeCity = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_to_entities[]=')) {
      query.split('filter_to_entities[]=').forEach((element, i) => {
        if (i === 0) {
          return;
        }
        const indexAux = element.indexOf('&');
        if (indexAux > 0) {
          serviceTypeToEntities.push(element.slice(0, indexAux));
        } else {
          serviceTypeToEntities.push(element);
        }
      });
    }

    if (query.includes('page=')) {
      const aux = query.split('page=')[1];
      const index = aux.indexOf('&');
      pageCurrent = index > 0 ? aux.slice(0, index) : aux;
      pageCurrent = Number.isInteger(parseInt(pageCurrent)) ? parseInt(pageCurrent) : 1;
    }

    this.setState({
      radioActived: filterActived,
      filterText,
      serviceType: {
        type: serviceTypeType,
        division: serviceTypeDivision,
        source: serviceTypeSource,
        state: serviceTypeState,
        city: serviceTypeCity,
        to_entities: serviceTypeToEntities,
      },
    });
    this.pageCurrent = pageCurrent;
  }

  resetFilters = () => {
    this.myFormRef.reset();
  };

  async searchApiDoc(serviceId) {
    try {
      const response = await getDocApi(serviceId);

      this.setState({
        schemaInputs: response.data.inputs,
        schemaOutputs: response.data.outputs,
        treatedInputs: response.data.treated_inputs,
        treatedOutputs: response.data.treated_outputs,
      });
    } catch (err) {
      this.setState({
        schemaInputs: null,
        schemaOutputs: null,
        treatedInputs: null,
        treatedOutputs: null,
      });
    } finally {
      this.setState({
        isSearchingDoc: false,
      });
    }
  }

  pagination() {
    for (let num = 1; num <= this.totalPages; num += 1) {
      this.paginationItems.push(
        <Pagination.Item
          key={num}
          onClick={async () => {
            this.paginationItems = [];
            this.pageCurrent = num;
            try {
              let filter = '';
              if (
                this.queryFilter !== null
                && this.queryFilter !== ''
                && !this.queryFilter.includes('page=')
              ) {
                window.history.pushState(
                  '',
                  '',
                  `${ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC}${this.queryFilter}&page=${this.pageCurrent}`,
                );
                filter = `${this.queryFilter}&page=${this.pageCurrent}`;
              } else {
                window.history.pushState('', '', `${ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC}?page=${this.pageCurrent}`);
                filter = `?page=${this.pageCurrent}`;
              }

              const response = await listService(filter);

              this.itensPerPage = response.headers['x-per-page'];
              const itensTotal = response.headers['x-total'];
              const aditionalPage = itensTotal % this.itensPerPage > 0 ? 1 : 0;

              this.totalPages = Math.floor(itensTotal / this.itensPerPage) + aditionalPage;

              this.pagination();

              this.setState({
                data: response.data.services,
                indexCollapseOpen: -1,
              });
            } catch (err) {
              if (err.response) {
                alert(err.response.data.msg_errors[0].msg);
              } else if (err.request) {
                alert(err.request);
              } else {
                alert(err.message);
              }
            }
          }}
        >
          {num}
        </Pagination.Item>,
      );
    }
  }

  render() {
    return (
      <Fragment>
        <AppNavbar />

        <Row>
          <Col sm={2}>
            <Menu />
          </Col>
          <Col sm={10}>
            <Form ref={el => (this.myFormRef = el)} inline>
              <FormControl
                type="text"
                value={this.state.filterText}
                placeholder="service_id/nome"
                className="mr-sm-2"
                onChange={(e) => {
                  this.setState({
                    filterText: e.target.value,
                  });
                }}
              />

              <FormGroup>
                <FormLabel>Tipo:</FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, type: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.type}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.type.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <FormLabel>Divisão: </FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, division: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.division}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.division.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Fonte de Dados: </FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, source: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.source}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.source.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Estado: </FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, state: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.state}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.state.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl
                  type="text"
                  value={this.state.serviceType.city}
                  placeholder="cidade"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, city: e.target.value.toUpperCase() },
                    }));
                  }}
                />
              </FormGroup>

              <Col sm={3}>
                <FormLabel>Entidades: </FormLabel>
                <FormGroup>
                  {this.state.msTypes != null
                    ? this.state.msTypes.to_entity.map((element, i) => (
                      <Form.Check
                        key={i.toString()}
                        id={`checkbox${element}`}
                        htmlFor={`checkbox${element}`}
                        value={element}
                        type="checkbox"
                        checked={this.state.serviceType.to_entities.includes(element)}
                        label={element}
                        style={{ margin: '5px' }}
                        onChange={(e) => {
                          if (this.state.serviceType.to_entities.includes(e.target.value)) {
                            const toEntitiesCopy = [...this.state.serviceType.to_entities];
                            toEntitiesCopy.splice(
                              this.state.serviceType.to_entities.indexOf(e.target.value),
                              1,
                            );
                            this.setState(prevState => ({
                              serviceType: {
                                ...prevState.serviceType,
                                to_entities: toEntitiesCopy,
                              },
                            }));
                          } else {
                            this.setState(prevState => ({
                              serviceType: {
                                ...prevState.serviceType,
                                to_entities: [...prevState.serviceType.to_entities, element],
                              },
                            }));
                          }
                        }}
                      />
                    ))
                    : ''}
                </FormGroup>
              </Col>

              <FormGroup>
                <FormLabel>Ativado: </FormLabel>
                <FormCheck
                  id="radioActivedTodos"
                  htmlFor="radioActivedTodos"
                  value="all"
                  type="radio"
                  checked={this.state.radioActived === 'all'}
                  label="Todos"
                  style={{ margin: '5px' }}
                  onChange={(e) => {
                    this.setState({
                      radioActived: e.target.value,
                    });
                  }}
                />
                <FormCheck
                  id="radioActivedSim"
                  htmlFor="radioActivedSim"
                  value="true"
                  type="radio"
                  checked={this.state.radioActived === 'true'}
                  label="Sim"
                  style={{ margin: '5px' }}
                  onChange={(e) => {
                    this.setState({
                      radioActived: e.target.value,
                    });
                  }}
                />
                <FormCheck
                  id="radioActivedNao"
                  htmlFor="radioActivedNao"
                  value="false"
                  type="radio"
                  checked={this.state.radioActived === 'false'}
                  label="Não"
                  style={{ margin: '5px' }}
                  onChange={(e) => {
                    this.setState({
                      radioActived: e.target.value,
                    });
                  }}
                />
              </FormGroup>
              <Button
                style={{ margin: '5px' }}
                variant="outline-warning"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    radioActived: 'all',
                    serviceType: {
                      type: '',
                      division: '',
                      source: '',
                      state: '',
                      city: '',
                      to_entities: [],
                    },
                    filterText: '',
                  });
                  this.resetFilters();
                  this.queryFilter = null;
                  window.history.pushState('', '', ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC);
                }}
              >
                Reset
              </Button>
              <Button
                style={{ margin: '5px' }}
                variant="outline-success"
                onClick={(e) => {
                  e.preventDefault();
                  this.onSearchServicesClick();
                }}
              >
                Search
              </Button>
            </Form>
            <Table responsive="sm" striped bordered hover className="table-list">
              <thead>
                <tr>
                  <th>#</th>
                  <th>service_id</th>
                  <th>Nome</th>
                  <th>Tempo (segs)</th>
                  <th>Ativado</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((item, i) => {
                  const index = i + 1 + (this.pageCurrent - 1) * this.itensPerPage;
                  return (
                    <Fragment key={index}>
                      <tr
                        onClick={() => {
                          this.setState(prevState => ({
                            indexCollapseOpen: i === prevState.indexCollapseOpen ? -1 : i,
                          }));
                          this.onServiceClick(item.service_id);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{index}</td>
                        <td>{item.service_id}</td>
                        <td>{item.name}</td>
                        <td>{item.response_time}</td>
                        {
                          item.actived
                            ? <td>✓</td>
                            : <td style={{ backgroundColor: '#fe8e8e' }}>N</td>
                        }
                        <td>
                          <ServiceType serviceType={item.service_type} />
                        </td>
                      </tr>
                      <tr hidden={i !== this.state.indexCollapseOpen}>
                        <td colSpan="6">
                          <Collapse in={i === this.state.indexCollapseOpen}>
                            {this.state.isSearchingDoc === true ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              <Fragment>
                                <Tabs defaultActiveKey="input_output" id="uncontrolled-tab-example">
                                  <Tab eventKey="input_output" title="Input/Output">
                                    <h6>JSON de input</h6>
                                    <TreatedInputsJson
                                      inputsOfService={this.state.treatedInputs}
                                      inputsSchemaOfService={this.state.schemaInputs}
                                      serviceId={item.service_id}
                                    />
                                    <h6>JSON de output</h6>
                                    <TreatedOutputsJson
                                      outputsOfService={this.state.treatedOutputs}
                                      serviceId={item.service_id}
                                    />
                                  </Tab>
                                  <Tab eventKey="schema" title="Schema">
                                    <h6>Schema de input</h6>
                                    <TreatedSchemaInputsJson jsonOfService={this.state.schemaInputs} serviceId={item.service_id} />
                                    <h6>Schema de output</h6>
                                    <TreatedOutputsJson outputsOfService={this.state.schemaOutputs} serviceId={item.service_id} />
                                  </Tab>
                                </Tabs>
                              </Fragment>
                            )}
                          </Collapse>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
            <Pagination>{this.paginationItems}</Pagination>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
