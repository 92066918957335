import React from 'react';
import ReactMarkdown from 'react-markdown';

import CodeBlock from '../../components/CodeBlock';
import InlineCode from '../../components/InlineCode';
import './styles.css';

const flattenElem = (text, child) => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flattenElem, text);
};

const MarkdownText = ({ contentMD }) => {
  /**
   * HeadingRenderer is a custom renderer
   * It parses the heading and attaches an id to it to be used as an anchor
   */
  const HeadingRenderer = (props) => {
    const { children, level } = props;
    const childrenArr = React.Children.toArray(children);
    const text = childrenArr.reduce(flattenElem, '');
    const slug = text.toLowerCase().replace(/[ |.]/g, '-');
    return React.createElement(`h${level}`, { id: slug }, childrenArr);
  };

  return (
    <div className="markdown-body">
      <ReactMarkdown
        source={contentMD}
        renderers={{
          code: CodeBlock,
          heading: HeadingRenderer,
          inlineCode: InlineCode,
        }}
        skipHtml={true}
        escapeHtml={false}
      />
    </div>
  );
};

export default MarkdownText;
