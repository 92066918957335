import React from 'react';

import styles from './styles';

class InlineCode extends React.PureComponent {
  render() {
    const { value } = this.props;

    return <code style={styles.container}>{value}</code>;
  }
}

export default InlineCode;
