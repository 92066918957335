import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';

import Menu from '../../components/Menu/Menu';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import './styles.css';

const Home = () => (
  <Fragment>
    <AppNavbar />

    <div style={{ position: 'absolute' }}>
      <Menu />
    </div>

    <Container>
      {''}
    </Container>

  </Fragment>
);

export default Home;
