export default {
  container: {
    backgroundColor: '#eee',
    color: '#222',
    padding: 4,
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: 'inherit',
  },
};
