import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Menu from '../../components/Menu/Menu';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import MarkdownText from '../../components/MarkdownText';

import contentMD from './content';

export default function OrchIntroduction() {
  return (
    <Fragment>
      <AppNavbar />

      <div style={{ position: 'absolute' }}>
        <Menu />
      </div>

      <Container>
        <Row>
          <Col>
            <MarkdownText contentMD={contentMD} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
