import React from 'react';
import { Navbar } from 'react-bootstrap';
import { FaFileAlt } from 'react-icons/fa';

import { ROUTE_PATH } from '../../helpers/constants';

const AppNavbar = () => (
  <Navbar bg="light" variant="light">
    <Navbar.Brand href={ROUTE_PATH.ROOT}>
      Services Docs
      <FaFileAlt />
    </Navbar.Brand>
  </Navbar>
);

export default AppNavbar;
