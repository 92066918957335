import React from 'react';
import { Nav } from 'react-bootstrap';

import { ROUTE_PATH } from '../../helpers/constants';

const Menu = () => (
  <Nav defaultActiveKey={ROUTE_PATH.HOME} className="flex-column" style={{ maxWidth: 240 }}>
    <Nav.Link href={ROUTE_PATH.HOME}>Home</Nav.Link>
    <Nav.Link href={ROUTE_PATH.ORCH_DOC}>Orquestrador - Introdução</Nav.Link>
    <Nav.Link href={ROUTE_PATH.ORCH_DOC_ASYNC}>Orquestrador - Serviços Assíncronos</Nav.Link>
    <Nav.Link href={ROUTE_PATH.ORCH_DOC_SYNC}>Orquestrador - Serviços Síncronos</Nav.Link>
    <Nav.Link href={ROUTE_PATH.ORCH_LIST_SERVICES_ASYNC}>Lista de Serviços Assíncronos</Nav.Link>
  </Nav>
);

export default Menu;
