import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_ORCH_DOCS_HOST,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const newConfig = config;

  if (token) {
    newConfig.headers.Authorization = token;
  }

  const apiKey = process.env.REACT_APP_ORCH_DOCS_KEY;
  if (apiKey) {
    newConfig.headers['x-api-key'] = apiKey;
  }

  return newConfig;
});

const reqMsType = () => api.get('/docs/mstype');

const listService = async (query = null) => {
  let urlFilters = '';

  if (query !== null) {
    urlFilters = query;
  }

  return api.get(`/docs/services${urlFilters}`);
};

const getDocApi = serviceId => api.get(`/docs/documentationapi?service_id=${serviceId}`);

export default api;
export { listService, getDocApi, reqMsType };
