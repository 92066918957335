import { ROUTE_PATH } from '../../helpers/constants';

export default `

# Orquestrador

Essa documentação descreve a utilização da API do Orquestrador.
O Orquestrador é uma API que centraliza as chamadas para os serviços da Inovamind.

##### Características do Orquestrador:
- As respostas são no formato json.
- É necessário ter um \`user_id\` e \`x-api-key\` para realizar as chamadas.
- São dois tipos de serviços:
  - **Síncronos**: A resposta para a chamada vem na própria requisição. Então se for realizado uma requisição POST para a API, ela já irá trazer os dados.
  - **Assíncronos**: A resposta para a chamada não vem na própria requisição, pois o processamento é feito em background e finalizado ápos um tempo. Então se for realizado uma requisição POST para a API, a requisição será processada em background e ápos finalizada, que os dados estarão disponivéis.

### Veja a documentação para cada tipo de serviço

#### [Serviços Assíncronos](${ROUTE_PATH.ORCH_DOC_ASYNC})
#### [Serviços Síncronos](${ROUTE_PATH.ORCH_DOC_SYNC})

`;
