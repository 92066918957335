const ORCH_ENDPOINT_SERVICES = '/v1/services/direct';

export default `

# Orquestrador - Serviços Síncronos

Essa documentação descreve as chamadas para **serviços síncronos**.

> Veja a lista de serviços [clicando aqui (Em breve)](#)

##### Características:
- As respostas são no formato json.
- As chamadas são síncronas, os dados da chamada (POST) virão no retorno da requisição.
- É necessário ter um \`user_id\` e \`x-api-key\` para realizar as chamadas.

## Tópicos

- [1 - Como utilizar](#1---como-utilizar)
  - [1.1 - Fazendo a chamada](#1-1---fazendo-a-chamada)
- [2 - Mensagens e Status](#2---mensagens-e-status)
  - [2.1 - Status de Erro](#2-1---status-de-erro)
  - [2.2 - Status de Informação](#2-2---status-de-informação)

## 1 - Como utilizar

### 1.1 - Fazendo a chamada

Para fazer a chamada, é necessário fazer uma requisição do tipo \`POST\` para a rota:
> ${ORCH_ENDPOINT_SERVICES}

e no corpo da requisição, o json com o seguinte formato:

\`\`\`json
{
  "user_id": "...",
  "service": {
    "service_id": "...",
    "inputs": {...}
  }
}
\`\`\`

Descrição dos campos do \`POST\`:

| Campo              | Tipo   | Obrigatório | Descrição                                |
|--------------------|--------|-------------|------------------------------------------|
| user_id            | String | *           | id do seu usuário                        |
| service            | Objeto | *           | objeto com o serviço a ser chamado       |
| service.service_id | String | *           | id do serviço                            |
| services.inputs    | Objeto | *           | inputs que serão enviados para o serviço |

Exemplo de requisição onde será chamado o serviço \`ocr_document_id\`

\`\`\`json
{
  "user_id": "{{user_id}}",
  "service": {
    "service_id": "ocr_document_id",
    "inputs": {
      "base64": "..."
    }
  }
}
\`\`\`

Resposta da requisição:
\`\`\`json
{
  "service_id": "ocr_document_id",
  "data": {...}
}
\`\`\`

Descrição dos campos de resposta do \`POST\`:

| Campo      | Tipo   | Descrição                                           |
|------------|--------|-----------------------------------------------------|
| service_id | String | id do serviço chamado                               |
| data       | Objeto | dados da resposta, são diferentes para cada serviço |

## 2 - Mensagens e Status

A mensagens e status são padronizados e tem 3 tipos: **Erro**, **Informação** e **Sistema de origem com problema**

### 2.1 - Status de Erro

Caso seja feita uma requisição incorreta para o orquestrador, ele irá retornar uma mensagem de erro junto do status code.

Tabela de mensagens de erro:

| Constante Status         | Descrição                                                    | Status Code |
|--------------------------|--------------------------------------------------------------|-------------|
| EXTERNAL_SERVER_ERROR    | Não foi possível obter as informações do servidor de origem. | 422         |
| INVALID_PARAMETERS_ERROR | Parâmetros inválidos.                                        | 400         |
| MISSING_PARAMETERS_ERROR | Há parâmetros obrigatórios ausentes.                         | 400         |
| NO_DATA_FOUND_ERROR      | Nenhum dado encontrado. (ao fornecer um token inexistente)   | 404         |
| GATEWAY_ERROR            | Limite de consultas excedido.                                | 429         |
| GATEWAY_ERROR            | x-api-key inválida.                                          | 403         |
| PROCESS_DATA_ERROR       | Ocorreu um erro ao processar os dados.                       | 422         |

### 2.2 - Status de Informação

As mensagens de informação são obtidas diretamente do sistema de origem.

Tabela de mensagens de informação:

| Constante Status           | Descrição                                                                                                | Status Code |
|----------------------------|----------------------------------------------------------------------------------------------------------|-------------|
| INVALID_PARAMETERS_INFO    | Paramêtros são inválidos para o sistema de origem.                                                       | 200         |
| UNAUTHORIZED_RESPONSE_INFO | Resposta não autorizada pelo sistema de origem.                                                          | 200         |
| NO_DATA_FOUND_INFO         | Nenhum dado encontrado no sistema de origem.                                                             | 200         |
| PDF_NOT_READED_INFO        | Não foi possível ler o conteúdo do pdf devido a má codificação do arquivo gerado pelo sistema de origem. | 200         |
| ORIGIN_SYSTEM_INFO         | O sistema de origem está com problemas para responder e iremos retentar em breve.                        | 202         |
| ORIGIN_SYSTEM_MESSAGE_INFO | Mensagem vinda do sistema de origem.                                                                     | 200         |

`;
