import React from 'react';
// const PropTypes = require('prop-types')
import hljs from 'highlight.js/lib/highlight';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';

// import 'highlight.js/styles/dracula.css';
// import 'highlight.js/styles/hybrid.css';
// import 'highlight.js/styles/vs2015.css';
// import 'highlight.js/styles/xt256.css';
import 'highlight.js/styles/monokai.css';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', json);

class CodeBlock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  setRef(el) {
    this.codeEl = el;
  }

  highlightCode() {
    hljs.highlightBlock(this.codeEl);
  }

  render() {
    const { language, value } = this.props;

    return (
      <pre>
        <code ref={this.setRef} className={`language-${language}`}>
          {value}
        </code>
      </pre>
    );
  }
}

export default CodeBlock;
